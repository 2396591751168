import { useState, useEffect, useContext } from 'react';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
// Assets
import Usa from 'assets/img/dashboards/usa.png';
// Custom components
import MiniCalendar from 'components/calendar/MiniCalendar';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import React from 'react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import CheckTable from 'views/admin/default/components/CheckTable';
// import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from 'views/admin/default/components/DailyTraffic';
import PieCard from 'views/admin/default/components/PieCard';
import Tasks from 'views/admin/default/components/Tasks';
import TotalSpent from 'views/admin/default/components/TotalSpent';
import WeeklyRevenue from 'views/admin/default/components/WeeklyRevenue';
import {
  columnsDataCheck,
  columnsDataComplex,
} from 'views/admin/default/variables/columnsData';
import tableDataCheck from 'views/admin/default/variables/tableDataCheck.json';
import tableDataComplex from 'views/admin/default/variables/tableDataComplex.json';
import axios from 'axios';
import rupee from '../../../../src/assets/img/Rupee.svg';
import expire from '../../../../src/assets/img/Expire.svg';
import refund from '../../../../src/assets/img/Refund.svg';
import { Image } from '@chakra-ui/react';

import { ReduxContext } from '../../../contexts/redux/ReduxState';
import withSession from '../../../withSession';
import { dateFormatter } from 'components/utils/utilFunctions';
import Loader from '../../../components/utils/Loader';

const UserReport = ({ isLoading }) => {
  const { user } = useContext(ReduxContext);
  const [transactionLoaderState, setTransactionLoaderState] = useState(false);
  // Chakra Color Mode
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const [analyticsData, setAnalyticsData] = useState({});
  const [analyticsData2, setAnalyticsData2] = useState([]);

  console.log(isLoading);

  const GetMerchantPayinAnalytics = async (
    fromDate = new Date(),
    toDate = new Date(),
  ) => {
    try {
      const myHeaders = {
        'Content-Type': 'application/json',
      };

      const reqBody = {
        merchant_id: user?.merchantId,
        from_date: dateFormatter(fromDate),
        to_date: dateFormatter(toDate),
      };

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: reqBody,
        redirect: 'follow',
      };

      setTransactionLoaderState(true);
      const response = await axios.post(
        'https://payinnew.remittpe.com/api/Intent/GetMerchantPayinAnalytics',
        reqBody,
        { headers: myHeaders },
      );
      setTransactionLoaderState(false);
      if (response.data.status == '0') {
        setAnalyticsData(response.data.data[0]);
      } else {
        console.log('Error: Invalid status from API');
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error('Error:', error);
    }
  };

  const GetMerchantPayinAnalyticsV1 = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      var raw = JSON.stringify({
        // merchant_id: user?.merchantId,
        merchant_id: '2',
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };
      setTransactionLoaderState(true);
      const response = await fetch(
        `https://payinnew.remittpe.com/api/Intent/GetMerchantPayinAnalyticsV1`,
        requestOptions,
      );
      const data = await response.json();

      if (response.status === 200) {
        if (data.status === '0') {
          setAnalyticsData2(data.data); // Assuming data.data is an array of merchants
        }
      } else {
        console.error('Error: ', data.message || 'Unknown error');
      }
      setTransactionLoaderState(false);
    } catch (error) {
      setTransactionLoaderState(false);
      console.error('Error fetching report data:', error);
    }
  };

  useEffect(() => {
    GetMerchantPayinAnalytics();
    GetMerchantPayinAnalyticsV1();
  }, []);

  return (
    <>
      {transactionLoaderState ? (
        <Loader />
      ) : (
        <Box
          pt={{ base: '130px', md: '80px', xl: '80px' }}
          style={{ marginTop: '0px' }}
        >
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Success"
              value={analyticsData?.succeess_count || 0}
            />

            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Image
                      src={rupee}
                      width="32px"
                      height="32px"
                      alt="Rupee Icon"
                      filter="invert(20%) sepia(100%) saturate(2000%) hue-rotate(210deg) brightness(90%) contrast(100%)"
                    />
                  }
                />
              }
              name="Failed"
              value={analyticsData?.failed_count || 0}
            />

            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Image
                      src={expire}
                      width="32px"
                      height="32px"
                      alt="Rupee Icon"
                      filter="invert(20%) sepia(100%) saturate(2000%) hue-rotate(210deg) brightness(90%) contrast(100%)"
                    />
                  }
                />
              }
              name="Expired"
              value={analyticsData?.expired_count || 0}
            />

            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
                />
              }
              name="Unsettled"
              value={analyticsData?.request_count || 0}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdFileCopy}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Dispute"
              value={analyticsData?.dispute_count || 0}
            />

            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Image
                      src={refund}
                      width="32px"
                      height="32px"
                      alt="Rupee Icon"
                      filter="invert(20%) sepia(100%) saturate(2000%) hue-rotate(210deg) brightness(90%) contrast(100%)"
                    />
                  }
                />
              }
              name="Refund"
              value={analyticsData?.refund_amount || 0}
            />
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
            <TotalSpent />
            <WeeklyRevenue />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
            <CheckTable
              columnsData={columnsDataCheck}
              tableData={tableDataCheck}
              analyticsData={analyticsData2}
            />
            <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px">
              <DailyTraffic />
              <PieCard />
            </SimpleGrid>
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
            {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
            {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid> */}
          </SimpleGrid>
        </Box>
      )}
    </>
  );
};

export default withSession(UserReport);
