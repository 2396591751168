'use client'
import React, { useContext, useEffect, useState } from "react";
import { Badge, Button, Modal, Form, FormControl } from "react-bootstrap";
import {
    Box,
    Flex,
    Progress,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import Loader from "../Loader";
import { Link } from '@chakra-ui/react';
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
import axios from 'axios';
import Swal from "sweetalert2";
import { ReduxContext } from "contexts/redux/ReduxState";

const columnHelper = createColumnHelper();
export default function ViewAllIpList(props) {
    const { user  } = React.useContext(ReduxContext);
    const [transactionLoaderState, setTransactionLoaderState] = useState(false);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('secondaryGray.500', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedMerchant, setSelectedMerchant] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        merchantSecret: "",
        ip_address: "",
    });
    const ViewAllMerchant = async () => {
        setTransactionLoaderState(true);
                try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                merchantId: user?.merchantId,
                // merchantId: "2",
                merchantSecret: "3DjyvAQvTZlk86g5jvHI7dfS",
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                `https://bumppy.in/payin/api/getAllMerchantIpList.php`,
                requestOptions
            );
            const data = await res.json();
            console.log("Merchant", data);
            if (data.status === "00") {
                setData(data.data);
            }
            setTransactionLoaderState(false);
        } catch (error) {
            console.log("error", error);
            setTransactionLoaderState(false);
        }
    };
    const handleOpenIP = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const addIP = async () => {
        try {
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                // merchantId: user?.merchantId,
                merchantId: "2",
                // merchantSecret: user?.merchantSecret,
                ip_address: formData.ip_address,
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            const res = await fetch(
                "https://bumppy.in/payin/api/addMerchantIpAddress.php",
                requestOptions
            );

            const responseData = await res.json();

            if (responseData.status === "00") {
                Swal.fire({
                    icon: "success",
                    title: "Add Successfully",
                    text: "Webhook added successfully!",
                });
                ViewAllMerchant();
                handleCloseModal();
            } else {
                console.error("Error adding webhook:", responseData.message);
            }
        } catch (error) {
            console.error("Error adding webhook:", error);
        }
    };
    useEffect(() => {
        ViewAllMerchant();
    }, []);

    const headers = [
        { id: 'Sr', label: 'Sr' },
        { id: 'UserId', label: 'User Id' },
        { id: 'ID', label: 'ID' },
        { id: 'Client Name', label: 'Client Name' },
        { id: 'IP Address', label: 'IP Address' },
        { id: 'IP Status', label: 'IP Status' },
        { id: 'Valid from', label: 'Valid from' },
        { id: 'valid To', label: 'valid To' },
    ];

    return (
        <div>
        {transactionLoaderState ? (
          <Loader />
        ) : (
            <Card
                flexDirection="column"
                w="100%"
                px="0px"
                overflowX={{ sm: 'scroll', lg: 'hidden' }}
            // style={{ marginTop: "5rem" }}
            >
                <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
                    <Text
                        color={textColor}
                        fontSize="22px"
                        fontWeight="700"
                        lineHeight="100%"
                        // className='mt-4'
                    >
                        View IP Merchant
                    </Text>
                </Flex>
                <Box>
                    <Table variant="simple" color="gray.500" mb="24px" mt="12px">
                        <Thead>
                            <Tr>
                                {headers.map((header) => (
                                    <Th
                                        key={header.id}
                                        pe="10px"
                                        borderColor={borderColor}
                                        cursor="pointer"
                                    >
                                        <Flex
                                            justifyContent="space-between"
                                            align="center"
                                            fontSize={{ sm: '15px', lg: '12px' }}
                                            color="gray.400"
                                        >
                                            {header.label}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((item, index) => (
                                <Tr
                                    key={item._id}
                                    style={{
                                        border: "none",
                                        height: "3rem",
                                        backgroundColor: "white",
                                        color: "black"
                                    }}
                                >
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}
                                    >
                                        {index + 1}
                                    </Td>
                                    <Td
                                        style={{ border: "none", width: "3rem" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.user_id}
                                    </Td>
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.id}

                                    </Td>
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.client_name || "NA"}
                                    </Td>
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.ip_address || "NA"}

                                    </Td>
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.ip_status || "NA"}

                                    </Td>
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.valid_from
                                            ? new Date(item.valid_from).toLocaleDateString()
                                            : "NA"}
                                    </Td>
                                    <Td
                                        style={{ border: "none" }}
                                        className="text-center align-middle"
                                        fontSize={{ sm: '12px', lg: '13px' }}

                                    >
                                        {item.valid_to
                                            ? new Date(item.valid_to).toLocaleDateString()
                                            : "NA"}
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Card>
  )}
      </div>
    );
};

