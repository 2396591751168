// Chakra imports
import { Portal, Box, useDisclosure } from '@chakra-ui/react';
import Footer from 'components/footer/FooterAdmin.js';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useContext, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from 'routes.js';

// Admin Imports
import MainDashboard from 'views/admin/default';
import NFTMarketplace from 'views/admin/marketplace';
import Profile from 'views/admin/profile';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';
// Auth Imports
import SignInCentered from 'views/auth/signIn';
import AllClient from 'views/admin/dataTables/components/AllClient';
import CreateClient from 'views/admin/dataTables/components/CreateClient';
import Developer from 'views/admin/dataTables/components/Developer';
import LoadRequest from 'views/admin/dataTables/components/LoadRequest';
import TransactionDetails from 'views/admin/dataTables/components/TransactionDetails';
import PayinReport from 'views/admin/dataTables/components/PayinReport';
import MyAccount from 'views/admin/dataTables/components/MyAccount';
import ClientLedger from 'views/admin/dataTables/components/ClientLedger';
import Settlement from 'views/admin/marketplace/settlement';
import { ReduxContext } from 'contexts/redux/ReduxState';
import Loader from 'views/admin/dataTables/Loader';
import ErrorPage from './ErrorPage';

// Custom Chakra theme
export default function Dashboard(props) {
  const { ...rest } = props;
  const { transactionLoader } = useContext(ReduxContext);
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === '/admin') {
        return (
          <Route path={`${route.path}`} element={route.component} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  document.documentElement.dir = 'ltr';
  return (
    <Box>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Sidebar routes={routes} display="none" {...rest} />
          {transactionLoader ? (
            <Loader />
          ) : (
            <Box
              float="right"
              minHeight="100vh"
              height="100%"
              overflow="auto"
              position="relative"
              maxHeight="100%"
              w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
              maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
              transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionDuration=".2s, .2s, .35s"
              transitionProperty="top, bottom, width"
              transitionTimingFunction="linear, linear, ease"
            >
              <Portal>
                <Box>
                  <Navbar
                    onOpen={onOpen}
                    logoText={'BUMPPY | PAYIN | PAYOUT'}
                    brandText={getActiveRoute(routes)}
                    secondary={getActiveNavbar(routes)}
                    message={getActiveNavbarText(routes)}
                    fixed={fixed}
                    {...rest}
                  />
                </Box>
              </Portal>

              <Box
                mx="auto"
                p={{ base: '20px', md: '30px' }}
                pe="20px"
                minH="100vh"
                pt="50px"
              >
                <Routes>
                  <Route path={`/dashboard`} element={<MainDashboard />} />
                  <Route path={`/settlement`} element={<Settlement />} />
                  <Route
                    path={`/settlement_process`}
                    element={<NFTMarketplace />}
                  />
                  <Route path={`/payin_ledger`} element={<ClientLedger />} />
                  <Route path={`/all_clients`} element={<AllClient />} />
                  <Route path={`/create_client`} element={<CreateClient />} />
                  <Route path={`/developer`} element={<Developer />} />
                  <Route path={`/load_request`} element={<LoadRequest />} />
                  <Route path={`/payin_report`} element={<PayinReport />} />
                  <Route path={`/`} element={<ErrorPage />} />

                  <Route
                    path={`/transaction_query`}
                    element={<TransactionDetails />}
                  />
                  <Route path={`/payout`} element={<MyAccount />} />

                  <Route path="/" element={<Navigate to="/" replace />} />
                </Routes>
              </Box>

              <Box>
                <Footer />
              </Box>
            </Box>
          )}
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
}
